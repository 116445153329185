// Imports
// ------
import styled, { css, keyframes } from 'styled-components';
import { breakup, Section, Div, H1, P } from '@tackl';

// Exports
// ------
export const Jacket = styled(Section)(
	(props) => css`
		position: relative;
		margin: 6rem 0 0 0;
		padding: ${props.theme.padS} 0 0;
		overflow: hidden;

		${breakup.medium`
			padding: ${props.theme.padM} 0 0 0;
		`}

		${breakup.large`
			padding: ${props.theme.padL} 0 0 0;
		`}

		&:before {
			content: '';
			position: absolute;
			z-index: 1;
			top: ${props.theme.padL};
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 1;
			${props.theme.grad(0, props.theme.bc4, `${props.theme.bc4}00`)}
		}
	`
);

const slideIn = keyframes`
    from {
        transform: translateY(-1.2rem) rotate(-6deg);
        opacity: 0;
    }

    to {
        transform: translateY(0rem) rotate(0deg);
        opacity: 1;
    }
`;

export const Title = styled(H1)(
	(props) => css`
		position: relative;
		z-index: 1;
		margin: 0 0 2.4rem 0;

		${breakup.large`
            margin: 0 0 6rem 0;
        `}

		.word {
			display: inline-flex;
		}

		.char {
			display: inline-flex;
			opacity: 0;

			${props.isAnimating &&
			css`
				animation: ${slideIn} 0.5s cubic-bezier(0.5, 0, 0.5, 1) both;
				animation-delay: calc(50ms * var(--char-index));
			`}
		}
	`
);

export const Text = styled(P)(
	(props) => css`
		position: relative;
		z-index: 1;
		padding: 0 0 ${props.theme.padS} 0;

		${breakup.medium`
            padding: 0 0 ${props.theme.padM} 0;
        `}

		${breakup.large`
            padding: 0 0 6rem 0;
        `}
	`
);

export const Images = styled(Div)(
	(props) => css`
		position: relative;
		z-index: 1;
	`
);

export const Image = styled.figure(
	(props) => css`
		position: relative;
		z-index: 1;
		overflow: hidden;
		border-radius: 12rem 0 0 0;

		${breakup.large`
            margin: 0 1.8rem;
            height: 100%;
			border-radius: 24rem 0 0 0;
        `}

		${props.right &&
		css`
			border-radius: 0 12rem 0 0;

			${breakup.large`
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				top: -${props.offset / 10}rem;
				height: auto;
				border-radius: 0 24rem 0 0;
            `}
		`}

		> div {
			position: relative;
			width: 100%;
			height: 100%;
			transform: scale(1.2);
		}

		.gatsby-image-wrapper {
			position: relative;
			width: 100%;
			height: 100%;
		}
	`
);
