// Imports
// ------
import React from 'react';
import Hero from './Hero';
import Testimonials from './Testimonials';
import Team from './Team';
import Accreditations from './Accreditations';
import ServicesCTA from '@parts/ServicesCTA';
import CallToAction from '@parts/CallToAction';
import { useDato } from './data';

// Component
// ------
function About() {
	// NOTE • Data
	const { servicesTitle, servicesText, servicesImage } = useDato();

	return (
		<>
			<Hero />
			<Testimonials />
			<Team />
			<Accreditations />
			<ServicesCTA
				title={servicesTitle}
				text={servicesText}
				image={servicesImage}
			/>
			<CallToAction />
		</>
	);
}

export default About;
