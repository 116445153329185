// Imports
// ------
import { useStaticQuery, graphql } from 'gatsby';

// Exports
// ------
export const useDato = () => {
	const data = useStaticQuery(
		graphql`
			query AboutAccredtations {
				about: datoCmsAboutPage {
					text: accreditationsText
				}

				logos: allDatoCmsAccreditation {
					nodes {
						id: originalId
						image {
							alt
							data: gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
						}
					}
				}
			}
		`
	);
	return data;
};
