// Imports
// ------
import React from 'react';
import Button from '@parts/Button';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

// Styles
// ------
import { Jacket, Heading } from './styles';

// Component
// ------
function CareersCTA({ toSpan }) {
	// NOTE Breakpoints
	const bp = useBreakpoint();

	// NOTE • Data
	const content = `We’re always looking for amazing staff to join our team`;

	const stack = toSpan !== 4;

	const sharedProps = {
		toSpan: toSpan,
		isStacked: stack,
	};

	return (
		<Jacket toSpan={toSpan} isStacked={stack}>
			<Heading toSpan={toSpan}>{content}</Heading>
			<Button label='View Careers' to='careers' isLight />
		</Jacket>
	);
}

export default CareersCTA;
