// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup, Section } from '@tackl';
import { pStyles } from '@type';

// Exports
// ------
export const Jacket = styled(Section)(
	(props) => css`
		position: relative;
		z-index: 2;
		background: ${props.theme.bc4};

		&:before {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			top: 0;
			background: ${props.theme.white};

			${breakup.large`
				content: '';
			`}
		}
	`
);

export const Coat = styled.div(
	(props) => css`
		display: flex;
		align-items: stretch;
		width: max-content;
		padding: 0 2.4rem;

		${breakup.medium`
			padding: 0 calc(8.333vw + 1.2rem);
		`}

		${breakup.large`
			justify-content: flex-start;
			padding: 0;
			width: 100%;
			overflow: visible;
		`}
	`
);

export const Sweater = styled.div(
	(props) => css`
		${props.theme.noscrollbars}
		position: relative;
		width: 100%;
		overflow: scroll;

		${breakup.large`
			overflow: visible;
		`}
	`
);

export const More = styled.a(
	(props) => css`
		${pStyles}
		z-index: 2;

		margin: 2.4rem 2.4rem ${props.theme.padS} 2.4rem;

		display: inline-flex;
		align-items: center;
		justify-content: flex-start;

		span {
			${props.theme.grad(90, props.theme.bc1, props.theme.bc2)}
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}

		${breakup.medium`
			margin: 3.6rem 2.4rem ${props.theme.padM} calc(8.333vw + 1.2rem);
		`}

		${breakup.large`
			position: absolute;
			top: 100%;
			right: 0;
			margin: 2.4rem 2.4rem 0 0;
		`}

		svg {
			margin: 0 0 0 0.6rem;
			width: 1.4rem;
			height: 1.4rem;
			stroke: ${props.theme.bc2};
			stroke-width: 2px;
		}
	`
);
