// Imports
// ------
import { useStaticQuery, graphql } from 'gatsby';

// Exports
// ------
export const useDato = () => {
	const data = useStaticQuery(
		graphql`
			query AboutTestimonials {
				about: datoCmsAboutPage {
					testimonials {
						id: originalId
						quote
						author
					}
				}
			}
		`
	);
	return data.about;
};
