// Imports
// ------
import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

// Styles
// ------
import { Jacket, Name, Role, Picture } from './styles';

// Component
// ------
function Member({ name, role, image }) {
	return (
		<Jacket>
			<Picture>
				<GatsbyImage image={image.data} alt={image.alt} />
			</Picture>

			<Name isBold>{name}</Name>
			<Role>{role}</Role>
		</Jacket>
	);
}

export default React.memo(Member);
