// Imports
// ------
import React from 'react';

// Styles
// ------
import { Jacket, Number, Quote, Author } from './styles';

// Component
// ------
function Single({ number, author, quote, isMiddle }) {
	return (
		<Jacket isMiddle={isMiddle} className={`testimonial-${number}`}>
			<Number>{number}</Number>
			<Quote>{quote}</Quote>
			<Author isBold>{author}</Author>
		</Jacket>
	);
}

export default React.memo(Single);
