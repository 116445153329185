// Imports
// ------
import React, { useRef, useState, useEffect } from 'react';
import AnimatedText from '@parts/AnimatedText';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Row, Column } from '@waffl';
import { useDato } from './data';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { GatsbyImage } from 'gatsby-plugin-image';
import { loaderState } from '@states/loader';
import { observer } from 'mobx-react-lite';

// Styles
// ------
import 'splitting/dist/splitting.css';
import 'splitting/dist/splitting-cells.css';
import { Jacket, Title, Text, Images, Image } from './styles';

// Component
// ------
gsap.registerPlugin(ScrollTrigger);

function Hero() {
	// NOTE • Breakpoints
	const bp = useBreakpoint();

	// NOTE • Refs
	const textRef = useRef();
	const imageLeft = useRef();
	const imageLeftInner = useRef();
	const imageRight = useRef();
	const imageRightInner = useRef();
	const trigger = useRef();
	const titleRef = useRef();

	// NOTE • States
	const [textHeight, setTextHeight] = useState();
	const [titleAni, setTitleAni] = useState(false);

	// NOTE • Data
	const { title, text, images, animatedText } = useDato();

	// NOTE • Split Title Characters
	useEffect(() => {
		let Splitting;
		if (typeof document !== 'undefined') {
			Splitting = require('splitting');

			Splitting({
				target: titleRef.current,
				by: 'chars',
			});
		}
	}, []);

	// NOTE • Onload: Work out image offset
	useEffect(() => {
		const calc = () => {
			setTextHeight(textRef.current.offsetHeight);
		};

		calc();
		window.addEventListener('resize', calc);

		return () => {
			window.removeEventListener('resize', calc);
		};
	}, []);

	// NOTE • Onload: Scroll Magic!
	useEffect(() => {
		const timer = setTimeout(() => {
			ScrollTrigger.matchMedia({
				'(min-width: 1024px)': () => {
					gsap.to(imageLeft.current, {
						y: `+=12%`,
						ease: 'none',
						scrollTrigger: {
							trigger: trigger.current,
							start: '25% 50%',
							end: '100% 50%',
							scrub: 0.5,
							markers: false,
						},
					});

					gsap.to(imageRight.current, {
						y: `+=-25%`,
						ease: 'none',
						scrollTrigger: {
							trigger: trigger.current,
							start: '25% 50%',
							end: '100% 50%',
							scrub: 0.5,
							markers: false,
						},
					});

					gsap.to(imageRightInner.current, {
						scale: 1,
						ease: 'none',
						scrollTrigger: {
							trigger: trigger.current,
							start: '25% 50%',
							end: '100% 50%',
							scrub: 0.5,
							markers: false,
						},
					});
				},
			});

			gsap.to(imageLeftInner.current, {
				scale: 1,
				ease: 'none',
				scrollTrigger: {
					trigger: trigger.current,
					start: '25% 50%',
					end: '100% 50%',
					scrub: 0.5,
					markers: false,
				},
			});
		}, 250);

		return () => {
			clearTimeout(timer);
		};
	}, []);

	// NOTE • Check for loader status
	useEffect(() => {
		if (loaderState.isNeeded === false) {
			setTitleAni(true);
		}
	}, [loaderState.isNeeded]);

	return (
		<Jacket ref={trigger}>
			<Row isExpanded>
				<Column small={12} medium={10} pushOnMedium={1} mpad>
					<Title ref={titleRef} isAnimating={titleAni}>
						{title}
					</Title>
				</Column>
			</Row>

			<Row isExpanded>
				<Column small={12} medium={10} pushOnMedium={1} large={5} mpad>
					<Text ref={textRef}>{text}</Text>
				</Column>
			</Row>

			<Images>
				<Row isExpanded isCollapsed isEqual>
					<Column small={12} medium={10} pushOnMedium={1} large={5} mpad>
						<Image ref={imageLeft}>
							<div ref={imageLeftInner}>
								<GatsbyImage image={images[0].data} alt={images[0].alt} />
							</div>
						</Image>
					</Column>

					{bp.large && (
						<Column small={12} medium={10} pushOnMedium={1} large={5} mpad>
							<Image right offset={textHeight} ref={imageRight}>
								<div ref={imageRightInner}>
									<GatsbyImage image={images[1].data} alt={images[1].alt} />
								</div>
							</Image>
						</Column>
					)}
				</Row>
			</Images>

			<AnimatedText text={animatedText} />
		</Jacket>
	);
}

export default observer(Hero);
