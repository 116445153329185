// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup, Div, H5, P, H6 } from '@tackl';

// Exports
// ------
export const Jacket = styled(Div)(
	(props) => css`
		position: relative;
		display: flex;
		flex-flow: column;
		align-items: flex-start;
		justify-content: space-between;

		min-width: calc(100vw - 4.8rem);
		width: calc(100vw - 4.8rem);
		max-width: calc(100vw - 4.8rem);
		height: 70vh;
		min-height: 36rem;
		max-height: 48rem;

		background: white;

		margin-left: 1.2rem;
		padding: ${props.theme.padS} 2.4rem;

		&:first-child {
			border-left: none;
			margin: 0;
		}

		${breakup.medium`
			padding: ${props.theme.padS} 3.6rem;
			min-width: calc(100vw - 36rem);
			width: calc(100vw - 36rem);
			max-width: calc(100vw - 36rem);
		`}

		${breakup.large`
			width: 100%;
			flex: 1;
            transform: translateY(0%);

			border: 2px solid ${props.theme.bc4}20;
			border-right: none;
			border-top: none;
			padding: 6rem;
			margin: 0;

			min-height: 48rem;
			height: 50vh;
			max-height: 50rem;
			max-width: none;
			width: 100%;
			min-width: 33.333%;
        `}
	`
);

const sharedStyles = (props) => css`
	color: ${props.theme.bc4};
`;

export const Number = styled(H5)(
	(props) => css`
		position: relative;
		display: inline-block;
		${props.theme.grad(90, props.theme.bc1, props.theme.bc2)}
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		margin: 0 0 2.4rem 0;

		${breakup.medium`
			margin: 0 0 3.6rem 0;
		`}

		&:before {
			content: '0';
			display: inline;
		}
	`
);

export const Quote = styled(P)(
	(props) => css`
		${sharedStyles}
		opacity: 0.6;
	`
);

export const Author = styled(H6)(
	(props) => css`
		${sharedStyles}
		margin-top: auto;
	`
);
