// Imports
// ------
import React from 'react';
import { Row, Column } from '@waffl';
import { useDato } from './data';
import { GatsbyImage } from 'gatsby-plugin-image';

// Styles
// ------
import { Jacket, Content, Heading, Text, Logos, Logo } from './styles';

// Component
// ------
function Accredtations() {
	// NOTE • Data
	const {
		about: { text },
		logos: { nodes },
	} = useDato();

	return (
		<Jacket padBottom>
			<Row isExpanded isEqual>
				<Column small={12} medium={10} pushOnMedium={1} large={5} mpad>
					<Content>
						<Heading>Accreditations</Heading>
						<Text>{text}</Text>
					</Content>
				</Column>

				<Column
					small={12}
					medium={10}
					pushOnMedium={1}
					pushOnLarge={2}
					large={4}
					mpad>
					<Logos>
						{nodes.map(({ id, image }) => (
							<Logo key={id}>
								<GatsbyImage image={image.data} alt={image.alt} />
							</Logo>
						))}
					</Logos>
				</Column>
			</Row>
		</Jacket>
	);
}

export default Accredtations;
