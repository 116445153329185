// Imports
// ------
import { useStaticQuery, graphql } from 'gatsby';

// Exports
// ------
export const useDato = () => {
	const data = useStaticQuery(
		graphql`
			query AboutHero {
				about: datoCmsAboutPage {
					title: heroTitle
					text: heroText
					images: heroImages {
						alt
						data: gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
					}
					animatedText
				}
			}
		`
	);
	return data.about;
};
