// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup, Div, H4, H5 } from '@tackl';
import { h4Styles, h5Styles } from '@type';

// Exports
// ------
export const Jacket = styled(Div)(
	(props) => css`
		${props.theme.grad(90, props.theme.bc1, props.theme.bc2)}

		display: flex;
		flex-flow: column;
		align-items: flex-start;
		justify-content: space-between;
		padding: ${props.theme.padS} 2.4rem;
		margin: ${props.theme.padS} 2.4rem 0;

		${breakup.medium`
            margin: ${props.theme.padM} calc(8.333vw + 1.2rem) 0;
            padding: ${props.theme.padS} 3.6rem;
        `}

		${breakup.large`
			flex-flow: ${props.isStacked ? `column` : `row`};
            align-items: ${props.isStacked ? `flex-start` : `center`};
            padding: 6rem;
			margin: 0;
            max-height: 36rem;
            margin-top: 0;
            min-width: 25%;
		    grid-column: span ${props.toSpan};
        `}

        ${props.toSpan === 2 &&
		css`
			${breakup.large`
                padding: 4.8rem;
            `}
		`}

        ${props.toSpan === 1 &&
		css`
			${breakup.large`
                padding: 2.4rem;
            `}
		`}
	`
);

export const Heading = styled(H4)(
	(props) => css`
		color: ${props.theme.white};
		margin-bottom: 2.4rem;

		${breakup.large`
            width: ${props.isStacked ? `100%` : `65%`};
            max-width: ${props.isStacked ? `none` : `70.4rem`};
        `}

		${props.toSpan === 1 &&
		css`
			${breakup.large`
                ${h5Styles}
                max-width: none;
		        width: 100%;
            `}
		`}

        ${props.toSpan === 2 &&
		css`
			${breakup.large`
                max-width: none;
		        width: 100%;
            `}
		`}

        ${props.toSpan === 3 &&
		css`
			${breakup.large`
                max-width: none;
		        width: 100%;
            `}
		`}

        ${props.toSpan === 4 &&
		css`
			${breakup.large`
                margin-bottom: 0;
            `}
		`}
	`
);
