// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup, Div, H6, P } from '@tackl';

// Exports
// ------
export const Jacket = styled(Div)(
	(props) => css`
		//-
	`
);

export const Name = styled(H6)(
	(props) => css`
		margin: 2.4rem 0 0 0;
	`
);

export const Role = styled(P)(
	(props) => css`
		margin: 0.6rem 0 0 0;
		opacity: 0.6;
	`
);

export const Picture = styled.figure(
	(props) => css`
		display: flex;
		flex-flow: column;
		align-items: flex-start;
		justify-content: flex-start;
		width: 100%;
		height: 20rem;

		${breakup.medium`
			height: 36rem;
		`}

		${breakup.large`
			height: 36rem;
		`}

		.gatsby-image-wrapper {
			width: 100%;
			height: 100%;

			img {
				object-position: top center;
			}
		}
	`
);
