// Imports
// ------
import React, { useEffect, useState } from 'react';
import Member from './Member';
import CareersCTA from '@parts/CareersCTA';
import { useDato } from './data';
import { Row, Column } from '@waffl';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

// Styles
// ------
import { Jacket, Title, People } from './styles';

// Component
// ------
function Team() {
	// NOTE • Breakpoints
	const bp = useBreakpoint();

	// NOTE • Data
	const { title, team } = useDato();

	// NOTE • States
	const [toSpan, setToSpan] = useState();

	// NOTE • Onload: Workout remaining grid space
	useEffect(() => {
		const number = team.length / 4;
		const equation = (number + '').split('.');
		const answer = parseInt(equation[1]);

		if (answer === 25) {
			setToSpan(3);
		} else if (answer === 5) {
			setToSpan(2);
		} else if (answer === 75) {
			setToSpan(1);
		} else {
			setToSpan(4);
		}
	}, [toSpan]);

	return (
		<Jacket padBottom>
			{/* Title */}
			<Row isExpanded>
				<Column small={12} medium={8} pushOnMedium={1} large={5} mpad>
					<Title>{title}</Title>
				</Column>
			</Row>

			{/* Team List */}
			<Row isExpanded>
				<Column small={12} medium={10} pushOnMedium={1} mpad>
					<People>
						{team.map(({ id, name, role, image }, i) => {
							return <Member key={id} name={name} role={role} image={image} />;
						})}

						{bp.large && <CareersCTA toSpan={toSpan} />}
					</People>
				</Column>
			</Row>

			{bp.large ? null : <CareersCTA toSpan={toSpan} />}
		</Jacket>
	);
}

export default Team;
