// Imports
// ------
import React, { useRef, useEffect } from 'react';
import Single from './Single';
import loadable from '@loadable/component';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useDato } from './data';

// Lazy
// ------
const Icon = loadable(() => import('@parts/Icons'));

// Styles
// ------
import { Jacket, Sweater, Coat, More } from './styles';

// Component
// ------
gsap.registerPlugin(ScrollTrigger);

function Testimonials() {
	// NOTE • Data
	const { testimonials } = useDato();

	// NOTE • Refs
	const trigger = useRef();

	// NOTE • Onload: Make magic...
	useEffect(() => {
		const timer = setTimeout(() => {
			ScrollTrigger.matchMedia({
				'(min-width: 1024px)': () => {
					gsap.to('.testimonial-1', {
						y: `+=-100%`,
						ease: 'none',
						scrollTrigger: {
							trigger: trigger.current,
							start: '0% 100%',
							end: '100% 50%',
							scrub: 0.5,
							markers: false,
						},
					});

					gsap.to('.testimonial-2', {
						y: `+=-50%`,
						ease: 'none',
						scrollTrigger: {
							trigger: trigger.current,
							start: '0% 100%',
							end: '100% 50%',
							scrub: 0.5,
							markers: false,
						},
					});
				},
			});
		}, 250);

		return () => {
			clearTimeout(timer);
		};
	}, []);

	// NOTE • External Link Props
	const extProps = {
		href: 'https://www.homecare.co.uk/homecare/agency.cfm/id/65432210109',
		target: '_blank',
		rel: 'noopener noreferrer',
	};

	return (
		<Jacket ref={trigger}>
			<Sweater>
				<Coat>
					{testimonials.map(({ id, quote, author }, i) => (
						<Single
							key={id}
							trigger={trigger}
							number={i + 1}
							quote={quote}
							author={author}
							isMiddle={i + 1 === 2}
						/>
					))}
				</Coat>
			</Sweater>

			<More {...extProps} aria-label='Go to Homecare.co.uk'>
				<span>See more on homecare.co.uk</span>
				<Icon type='link' />
			</More>
		</Jacket>
	);
}

export default Testimonials;
