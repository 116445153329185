// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup, Section, Div, H2 } from '@tackl';

// Exports
// ------
export const Jacket = styled(Section)(
	(props) => css`
		position: relative;
		z-index: 1;
		background: ${props.theme.white};
		color: ${props.theme.bc4};

		padding-top: ${props.theme.padS};

		${breakup.medium`
			padding-top: ${props.theme.padM};
		`}

		${breakup.large`
			padding-top: 0;
		`}
	`
);

export const Title = styled(H2)(
	(props) => css`
		margin: 0 0 2.4rem 0;

		${breakup.medium`
			margin: 0 0 4.8rem 0;
		`}

		${breakup.large`
			margin: 0 0 6rem 0;
		`}
	`
);

export const People = styled(Div)(
	(props) => css`
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-auto-rows: auto;
		grid-gap: 1.2rem 0.6rem;

		${breakup.smedium` grid-gap: 2.4rem 1.2rem; `}

		${breakup.medium` grid-gap: 3.6rem; `}

		${breakup.large`
			grid-template-columns: repeat(4, 1fr);
		`}
	`
);
