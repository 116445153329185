// Imports
// ------
import { useStaticQuery, graphql } from 'gatsby';

// Exports
// ------
export const useDato = () => {
	const data = useStaticQuery(
		graphql`
			query AboutServices {
				about: datoCmsAboutPage {
					servicesTitle
					servicesText
					servicesImage {
						alt
						data: gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
					}
				}
			}
		`
	);
	return data.about;
};
