// Imports
// ------
import { useStaticQuery, graphql } from 'gatsby';

// Exports
// ------
export const useDato = () => {
	const data = useStaticQuery(
		graphql`
			query AboutTeam {
				about: datoCmsAboutPage {
					title: teamTitle
					team: teamMembers {
						id: originalId
						name
						role
						image: picture {
							alt
							data: gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
						}
					}
				}
			}
		`
	);
	return data.about;
};
