// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup, Section, H2, P } from '@tackl';

// Exports
// ------
export const Jacket = styled(Section)(
	(props) => css`
		position: relative;
		z-index: 2;
		background: ${props.theme.white};
		color: ${props.theme.bc4};
	`
);

export const Content = styled.div(
	(props) => css`
		color: inherit;
		height: 100%;
		display: flex;
		flex-flow: column;
		align-items: flex-start;
		justify-content: center;
	`
);

export const Heading = styled(H2)(
	(props) => css`
		color: inherit;
		margin: 0 0 1.8rem 0;

		${breakup.large`
            margin: 0 0 2.4rem 0;
        `}
	`
);

export const Text = styled(P)(
	(props) => css`
		opacity: 0.6;
		color: inherit;
		margin: 0 0 3.6rem;

		${breakup.medium`
            margin: 0 0 4.8rem;
        `}

		${breakup.large`
            margin: 0;
        `}
	`
);

export const Logos = styled.div(
	(props) => css`
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 1.2rem;

		${breakup.medium`
            grid-template-columns: repeat(4, 1fr);
            grid-gap: 1.2rem;
        `}

		${breakup.large`
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 3.6rem;
        `}
	`
);

export const Logo = styled.figure(
	(props) => css`
		//-
	`
);
